import { TweenMax, Power4 } from 'gsap/all'

import { getDocumentElements, getViewportHeight } from '../utils'

const [
    navigation,
    navigationDrawer,
    navigationContent,
    navigationSections,
    navigationScreens,
] = getDocumentElements([
    { className: 'js-tet-navigation' },
    { className: 'js-tet-navigation__drawer' },
    { className: 'js-tet-navigation__content' },
    { className: 'js-tet-navigation__section', getAllElements: true },
    { className: 'js-tet-navigation__screen', getAllElements: true },
])

const init = () => {
    if (
        navigation &&
        navigationDrawer &&
        navigationContent &&
        navigationSections &&
        navigationScreens
    ) {
        const pointA = { x: null, y: null }
        const pointB = { x: null, y: null }
        const pointC = { x: null, y: null }
        const cursorPoint = { x: null, y: null }

        let hoveredSection = null
        let activeScreen = null
        let isTriangleHovered = false

        let recalculationTimeout = null
        const timeoutDuration = 50
        const drawerTimeoutDuration = 70

        // -------------------- DRAWER ANIMATION --------------------
        let navigationAnimationTimeout
        const drawerInAnimation = () => {
            window.clearTimeout(navigationAnimationTimeout)
            TweenMax.to(navigationDrawer, 0.35, {
                display: 'block',
                autoAlpha: 1,
                x: 0,
                ease: Power4.easeOut,
            })
        }
        const drawerOutAnimation = () => {
            navigationAnimationTimeout = window.setTimeout(() => {
                TweenMax.to(navigationDrawer, 0.15, {
                    display: 'none',
                    autoAlpha: 0,
                    x: -22,
                    ease: Power4.easeOut,
                })
            }, drawerTimeoutDuration)
        }

        // -------------------- DRAWER ANIMATION END --------------------

        navigation.addEventListener('mouseenter', () => {
            document.addEventListener('mousemove', onMouseMove)
        })

        navigation.addEventListener('mouseleave', () => {
            document.removeEventListener('mousemove', onMouseMove)
            drawerOutAnimation()
        })

        navigationContent.addEventListener('mouseleave', () => {
            clearTimeout(recalculationTimeout)
            isTriangleHovered = false
        })

        Array.from(navigationSections).map(section =>
            section.addEventListener('click', () => {
                clearTimeout(recalculationTimeout)
                isTriangleHovered = false
                activeScreen = findActiveScreen()
                activeScreen.classList.add('is-visible')
                removeClassFromItemsExceptActive({
                    className: 'is-visible',
                    items: navigationScreens,
                    activeItem: activeScreen,
                })
            })
        )

        const arePointsValid = ({ P, A, B, C }) =>
            P.x && P.y && A.x && A.y && B.x && B.y && C.x && C.y

        const isPointInTriangle = ({ P, A, B, C }) => {
            if (!arePointsValid({ P, A, B, C })) {
                return false
            }

            const w1 =
                (A.x * (C.y - A.y) + (P.y - A.y) * (C.x - A.x) - P.x * (C.y - A.y)) /
                ((B.y - A.y) * (C.x - A.x) - (B.x - A.x) * (C.y - A.y))
            const w2 = (P.y - A.y - w1 * (B.y - A.y)) / (C.y - A.y)

            return w1 >= 0 && w2 >= 0 && w1 + w2 <= 1
        }

        const findHoveredSection = ({ event }) =>
            event.target.closest('.js-tet-navigation__section')

        const findActiveScreen = () =>
            Array.from(navigationScreens).find(
                screen =>
                    screen.dataset.navigationScreen === hoveredSection.dataset.navigationScreenKey
            )

        const removeClassFromItemsExceptActive = ({ className, items, activeItem }) =>
            Array.from(items).map(item => {
                if (item !== activeItem) {
                    item.classList.remove(className)
                }
            })

        const onMouseMove = event => {
            hoveredSection = findHoveredSection({ event })
            removeClassFromItemsExceptActive({
                className: 'is-hovered',
                items: navigationSections,
                activeItem: hoveredSection,
            })

            if (!hoveredSection && !isTriangleHovered) {
                return
            }

            if (hoveredSection) {
                hoveredSection.classList.add('is-hovered')
            }

            if (hoveredSection && !isTriangleHovered) {
                activeScreen = findActiveScreen()
                removeClassFromItemsExceptActive({
                    className: 'is-visible',
                    items: navigationScreens,
                    activeItem: activeScreen,
                })
            }

            clearTimeout(recalculationTimeout)
            const screenRectangle = activeScreen.getBoundingClientRect()
            const viewportHeight = getViewportHeight()

            cursorPoint.x = event.clientX
            cursorPoint.y = event.clientY
            pointB.x = screenRectangle.left
            pointB.y = screenRectangle.top
            pointC.x = screenRectangle.left

            // Limit pointC.y to max viewport height
            if (screenRectangle.bottom - screenRectangle.top > viewportHeight) {
                pointC.y = viewportHeight
            } else {
                pointC.y = screenRectangle.bottom - screenRectangle.top
            }

            if (
                isPointInTriangle({
                    P: cursorPoint,
                    A: pointA,
                    B: pointB,
                    C: pointC,
                })
            ) {
                isTriangleHovered = true

                if (hoveredSection) {
                    recalculationTimeout = window.setTimeout(() => {
                        activeScreen = findActiveScreen()
                        activeScreen.classList.add('is-visible')
                        removeClassFromItemsExceptActive({
                            className: 'is-visible',
                            items: navigationScreens,
                            activeItem: activeScreen,
                        })
                        drawerInAnimation()
                    }, timeoutDuration)
                }
            } else {
                isTriangleHovered = false

                pointA.x = event.clientX
                pointA.y = event.clientY

                activeScreen.classList.add('is-visible')
                drawerInAnimation()
            }
        }
    }
}

export { init }
