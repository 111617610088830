import { getDocumentElements, getScrollTop, getScrollLeft } from '../utils'

const [rippleContainers] = getDocumentElements([
    { className: 'js-tet-ripple', getAllElements: true },
])

const init = () => {
    if (rippleContainers) {
        const animateRipple = e => {
            if (!e.target.classList.contains('js-tet-ripple__inner')) {
                const rippleEl = e.target.querySelectorAll('.js-tet-ripple__inner')[0]

                rippleEl.classList.remove('is-active')
                window.setTimeout(() => {
                    rippleEl.classList.add('is-active')
                }, 0)

                const dimensions = Math.max(e.target.offsetHeight, e.target.offsetWidth)
                rippleEl.style.height = `${dimensions}px`
                rippleEl.style.width = `${dimensions}px`

                const rect = e.target.getBoundingClientRect()

                const offset = {
                    top: rect.top + getScrollTop(),
                    left: rect.left + getScrollLeft(),
                }

                const x = e.pageX - offset.left - rippleEl.offsetWidth / 2
                const y = e.pageY - offset.top - rippleEl.offsetHeight / 2

                rippleEl.style.top = `${y}px`
                rippleEl.style.left = `${x}px`
            }
        }

        Array.from(rippleContainers).map(rippleContainer => {
            if (rippleContainer.getAttribute('tet-listener') !== 'true') {
                rippleContainer.addEventListener('mousedown', animateRipple)
                rippleContainer.setAttribute('tet-listener', 'true')
            }
        })
    }
}

export { init }
