import { TimelineLite, Power4, Power3, Power2, Power0 } from 'gsap/all'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import { getDocumentElements, getViewportWidth, getViewportHeight } from '../utils'
import { inherit } from 'highlight.js'

const [
    mobileNav,
    mobileNavDark,
    mobileNavFAB,
    mobileNavIcon,
    mobileSearchIcon,
    mobileNavContent,
    mobileNavCloseContainers,
    mobileNavSections,
    mobileNavOverlay,
    mobileNavSide,
    mobileNavBodies,
    mobileNavHomeScreenWrapper,
    mobileNavScreenWrappers,
    mobileNavHomeScreen,
    mobileNavScreens,
    mobileNavBackContainers,
] = getDocumentElements([
    { className: 'js-mobile-nav' },
    { className: 'js-mobile-nav--dark' },
    { className: 'js-mobile-nav__fab' },
    { className: 'js-mobile-nav__icon' },
    { className: 'js-mobile-nav__icon-search' },
    { className: 'js-mobile-nav__content' },
    { className: 'js-mobile-nav__close-container', getAllElements: true },
    { className: 'js-mobile-nav__section', getAllElements: true },
    { className: 'js-mobile-nav__overlay' },
    { className: 'js-mobile-nav__side' },
    { className: 'js-mobile-nav__body', getAllElements: true },
    { className: 'js-mobile-nav__screen-wrapper--home' },
    { className: 'js-mobile-nav__screen-wrapper', getAllElements: true },
    { className: 'js-mobile-nav__screen--home' },
    { className: 'js-mobile-nav__screen', getAllElements: true },
    { className: 'js-mobile-nav__back-container', getAllElements: true },
])

const init = () => {
    if (
        (mobileNav || mobileNavDark) &&
        mobileNavIcon &&
        mobileSearchIcon &&
        mobileNavFAB &&
        mobileNavContent &&
        mobileNavCloseContainers &&
        mobileNavSections &&
        mobileNavOverlay &&
        mobileNavSide &&
        mobileNavBodies &&
        mobileNavHomeScreenWrapper &&
        mobileNavHomeScreen &&
        mobileNavScreenWrappers &&
        mobileNavScreens &&
        mobileNavBackContainers
    ) {
        const mobileNavEl = mobileNav || mobileNavDark

        const viewportWidth = getViewportWidth()
        const viewportHeight = getViewportHeight()
        let scale = 1
        let pageOffset
        let oldPageOffset

        if (viewportWidth >= viewportHeight) {
            scale = (viewportWidth / mobileNavFAB.offsetWidth) * 2.7
        } else {
            scale = (viewportHeight / mobileNavFAB.offsetHeight) * 2.7
        }

        window.addEventListener('scroll', function() {
            pageOffset = window.scrollY
        })

        // -------------------- Open FAB Animation --------------------

        const addMobileNavOpenClass = () => mobileNavEl.classList.add('is-open')

        const openFABAnimation = () => {
            oldPageOffset = pageOffset

            window.scrollTo(0, 1)

            if (mobileNavEl.classList.contains('is-open')) return

            Array.from(mobileNavBodies).map(mobileNavBody => disableBodyScroll(mobileNavBody))
            mobileNavFAB.classList.add('is-open')

            document.body.classList.add('is-open')

            const openFABAnimationTimeline = new TimelineLite()

            openFABAnimationTimeline.timeScale(1 / 1)

            openFABAnimationTimeline
                .call(addMobileNavOpenClass)
                .to(mobileNavSide, 0.3, {
                    left: 0,
                })
                .to(
                    mobileNavOverlay,
                    0.3,
                    {
                        display: 'block',
                        autoAlpha: 1,
                    },
                    '-=0.3'
                )
                .to(
                    [mobileNavIcon, mobileSearchIcon],
                    0,
                    {
                        display: 'none',
                    },
                    '-=0.3'
                )
                .to(
                    mobileNavFAB,
                    0,
                    {
                        width: 'calc(100% - 3.5rem)',
                        zIndex: 999,
                    },
                    '-=0.3'
                )
        }

        mobileNavIcon.onclick = () => openFABAnimation()

        // -------------------- Close FAB Animation --------------------

        const cleanUpOpenScreens = () => {
            Array.from(mobileNavScreenWrappers).map(mobileNavScreenWrapper => {
                if (mobileNavScreenWrapper.classList.contains('is-open')) {
                    mobileNavScreenWrapper.classList.remove('is-open')

                    closeScreenAnimation({ el: mobileNavScreenWrapper, duration: 0 })
                }
            })
        }

        const removeMobileNavOpenClass = () => mobileNavEl.classList.remove('is-open')

        const closeFABAnimation = () => {
            if (!mobileNavEl.classList.contains('is-open')) return

            Array.from(mobileNavBodies).map(mobileNavBody => enableBodyScroll(mobileNavBody))
            // body.classList.remove('no-scroll')
            mobileNavFAB.classList.remove('is-open')

            document.body.classList.remove('is-open')

            window.scrollTo(0, oldPageOffset)

            const closeFABAnimationTimeline = new TimelineLite()

            closeFABAnimationTimeline.timeScale(1 / 1)

            closeFABAnimationTimeline

                .to(mobileNavSide, 0.3, {
                    left: '-100%',
                })
                .to(
                    mobileNavOverlay,
                    0.3,
                    {
                        display: 'none',
                        autoAlpha: 0,
                    },
                    '-=0.3'
                )
                .to(
                    [mobileNavIcon, mobileSearchIcon],
                    0,
                    {
                        display: 'flex',
                    },
                    '-=0.3'
                )
                .to(
                    mobileNavFAB,
                    0,
                    {
                        width: '100%',
                        zIndex: 'inherit',
                    },
                    '-=0.3'
                )
                .call(removeMobileNavOpenClass)
                .call(cleanUpOpenScreens)
        }

        Array.from(mobileNavCloseContainers).map(closeContainer => {
            closeContainer.onclick = () => closeFABAnimation()
        })

        mobileNavOverlay.onclick = () => closeFABAnimation()

        // -------------------- SCREEN IN --------------------

        const openScreenAnimation = ({ section }) => {
            const { mobileNavScreenKey } = section.dataset
            const sectionScreen = Array.from(mobileNavScreenWrappers).find(
                mobileNavScreen => mobileNavScreen.dataset.mobileNavScreen === mobileNavScreenKey
            )
            sectionScreen.classList.add('is-open')

            const openScreenAnimationTimeline = new TimelineLite()

            openScreenAnimationTimeline.timeScale(1 / 1)

            openScreenAnimationTimeline
                .to(sectionScreen, 0.3, {
                    display: 'block',
                    left: 0,
                    ease: Power2.easeOut,
                })
                .to(
                    mobileNavFAB,
                    0,
                    {
                        zIndex: 99,
                    },
                    '-=0.3'
                )
        }

        Array.from(mobileNavSections).map(section => {
            section.onclick = () => openScreenAnimation({ section })
        })

        // -------------------- SCREEN OUT --------------------

        const closeScreenAnimation = ({ el, duration }) => {
            const closeScreenAnimationTimeline = new TimelineLite()

            closeScreenAnimationTimeline.timeScale(1 / 1)

            closeScreenAnimationTimeline
                .to(el, duration, {
                    left: '100%',
                    ease: Power3.easeOut,
                })
                .to(
                    mobileNavFAB,
                    0,
                    {
                        zIndex: 999,
                    },
                    '-=0.3'
                )
        }

        Array.from(mobileNavBackContainers).map(backContainer => {
            backContainer.onclick = () => {
                const screenWrapper = Array.from(mobileNavScreenWrappers).find(
                    mobileNavScreenWrapper => mobileNavScreenWrapper.classList.contains('is-open')
                )
                screenWrapper.classList.remove('is-open')

                closeScreenAnimation({
                    el: screenWrapper,
                    duration: 0.3,
                })
            }
        })
    }
}

export { init }
