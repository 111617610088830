import { CSSPlugin, AttrPlugin, ScrollToPlugin } from 'gsap/all'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

// eslint-disable-next-line
const plugins = [CSSPlugin, AttrPlugin, ScrollToPlugin]

import { isDeviceHandheld } from './components/utils'

import '../styles/styles.scss'

class TetWebComponents {
    constructor() {
        if (!TetWebComponents.instance) {
            TetWebComponents.instance = this
        }

        return TetWebComponents.instance
    }

    init() {
        if (isDeviceHandheld()) document.body.classList.add('mobile')

        this.navigationInit()
        this.mobileNavInit()
        this.bannerInit()
        this.rippleInit()
        this.accordionInit()
        this.tabsInit()
        this.modalInit()
        this.tooltipInit()
    }

    navigationInit() {
        if (this.elementWithClassExists('js-tet-navigation')) {
            return this.importCommands('navigation')
        }
    }

    mobileNavInit() {
        if (
            this.elementWithClassExists('js-mobile-nav') ||
            this.elementWithClassExists('js-mobile-nav--dark')
        ) {
            return this.importCommands('mobile-nav')
        }
    }

    bannerInit() {
        if (this.elementWithClassExists('js-tet-banner')) {
            return this.importCommands('banner')
        }
    }

    rippleInit() {
        if (this.elementWithClassExists('js-tet-ripple')) {
            return this.importCommands('ripple')
        }
    }

    accordionInit() {
        if (this.elementWithClassExists('js-tet-accordion')) {
            return this.importCommands('accordion')
        }
    }

    tabsInit() {
        if (this.elementWithClassExists('js-tet-tabs')) {
            return this.importCommands('tabs')
        }
    }

    modalInit() {
        if (this.elementWithClassExists('js-tet-modal')) {
            this.importCommands('modal')
        }
    }

    tooltipInit() {
        if (this.elementWithClassExists('js-tet-tooltip')) {
            this.importCommands('tooltip')
        }
    }

    elementWithClassExists(className) {
        return document.getElementsByClassName(className).length > 0
    }

    importCommands(componentName) {
        const code = require(`./components/${componentName}/tet-${componentName}.js`)
        code.init()
    }

    disableBodyScroll = htmlEl => disableBodyScroll(htmlEl)
    enableBodyScroll = htmlEl => enableBodyScroll(htmlEl)
}

const instance = new TetWebComponents()
Object.freeze(instance)

export default instance
