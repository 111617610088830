import { TweenMax, Power3 } from 'gsap/all'

import { getDocumentElements } from '../utils'

const [accordions] = getDocumentElements([{ className: 'js-tet-accordion', getAllElements: true }])

const init = () => {
    if (accordions) {
        const articleToggleAnimation = ({ article }) => {
            const content = article.querySelector('.js-tet-accordion__content')

            const animationDuration = 0.4

            if (article.classList.contains('is-open')) {
                TweenMax.to(content, animationDuration, { height: 0, ease: Power3.easeOut })
                article.classList.remove('is-open')
            } else {
                TweenMax.set(content, { height: 'auto' })
                requestAnimationFrame(() => {
                    TweenMax.from(content, animationDuration, { height: 0, ease: Power3.easeOut })
                })

                article.classList.add('is-open')
            }
        }

        // ------------------------------

        Array.from(accordions).map(accordion => {
            const articles = accordion.querySelectorAll('.js-tet-accordion__article')

            Array.from(articles).map(article => {
                const header = article.querySelector('.js-tet-accordion__header')

                if (header.getAttribute('tet-listener') !== 'true') {
                    header.addEventListener('click', () => articleToggleAnimation({ article }))
                    header.setAttribute('tet-listener', 'true')
                }
            })
        })
    }
}

export { init }
