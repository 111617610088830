import { TimelineLite, Power4 } from 'gsap/all'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import { getDocumentElements } from '../utils'

const [modal, overlay] = getDocumentElements([
    { className: 'js-tet-modal' },
    { className: 'js-tet-modal__overlay' },
])

const init = () => {
    if (modal && overlay) {
        const modalFadeInDuration = 0.4
        const modalFadeOutDuration = 0.3

        const modalFadeDistance = 22

        const timeScale = 1 / 1

        const modalOpenAnimation = ({ modalScreenKey }) => {
            if (modalScreenKey) {
                const modalScreens = modal.getElementsByClassName('js-tet-modal__screen')

                Array.from(modalScreens).map(modalScreen => {
                    if (modalScreen.dataset.modalScreen === modalScreenKey) {
                        modalScreen.classList.add('is-active')
                    } else {
                        modalScreen.classList.remove('is-active')
                    }
                })
            }

            const modalScreenBodies = modal.getElementsByClassName('js-tet-modal__body')

            Array.from(modalScreenBodies).map(modalScreenBody => disableBodyScroll(modalScreenBody))

            const modalOpenAnimationTimeline = new TimelineLite()
            modalOpenAnimationTimeline.timeScale(timeScale)

            modalOpenAnimationTimeline
                .fromTo(
                    overlay,
                    modalFadeInDuration * 0.8,
                    { autoAlpha: 0 },
                    { autoAlpha: 1, ease: Power4.easeOut },
                    'start'
                )
                .fromTo(
                    modal,
                    modalFadeInDuration,
                    { autoAlpha: 0, y: modalFadeDistance, x: '-50%' },
                    { autoAlpha: 1, y: 0, ease: Power4.easeOut, x: '-50%' },
                    'start'
                )
        }

        const modalCloseAnimation = () => {
            const modalCloseAnimationTimeline = new TimelineLite()
            modalCloseAnimationTimeline.timeScale(timeScale)

            const modalScreenBodies = modal.getElementsByClassName('js-tet-modal__body')

            Array.from(modalScreenBodies).map(modalScreenBody => enableBodyScroll(modalScreenBody))

            modalCloseAnimationTimeline
                .fromTo(
                    overlay,
                    modalFadeOutDuration * 0.8,
                    { autoAlpha: 1 },
                    { autoAlpha: 0, ease: Power4.easeOut },
                    'start'
                )
                .fromTo(
                    modal,
                    modalFadeOutDuration,
                    { autoAlpha: 1, y: 0 },
                    { autoAlpha: 0, y: modalFadeDistance, ease: Power4.easeOut },
                    'start'
                )
        }

        // BUILT IN ELEMENT TRIGGERS

        const openingElements = document.getElementsByClassName('js-tet-modal__opening-element')

        if (Array.from(openingElements).length > 0) {
            Array.from(openingElements).map(openingElement => {
                openingElement.addEventListener('click', () =>
                    modalOpenAnimation({ modalScreenKey: openingElement.dataset.modalScreenKey })
                )
            })
        }

        const closeButtons = modal.getElementsByClassName('js-tet-modal__close')

        if (Array.from(closeButtons).length > 0) {
            Array.from(closeButtons).map(closeButton => {
                closeButton.addEventListener('click', () => modalCloseAnimation())
            })
        }

        if (overlay) {
            overlay.addEventListener('click', () => modalCloseAnimation())
        }

        // CUSTOM EVENT TRIGGERS

        modal.addEventListener('tet.modal.open', e =>
            modalOpenAnimation({ modalScreenKey: e.detail && e.detail.modalScreenKey })
        )

        modal.addEventListener('tet.modal.close', () => modalCloseAnimation())
    }
}

export { init }
