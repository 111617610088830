import { TimelineLite, Power3 } from 'gsap/all'
import Hammer from 'hammerjs'

import { getDocumentElements, getViewportWidth } from '../utils'

const [banner, bannerSlides] = getDocumentElements([
    { className: 'js-tet-banner' },
    { className: 'js-tet-banner__slide', getAllElements: true },
])

const init = () => {
    if (banner && bannerSlides && bannerSlides.length > 1) {
        // -------------------- GENERATE SWITCH BUTTONS --------------------

        const switchButtons = []

        const switchEl = document.createElement('section')
        switchEl.classList.add('tet-banner__switch')

        banner.appendChild(switchEl)

        Array.from(bannerSlides).map(slide => {
            const switchButton = document.createElement('span')
            switchButton.classList.add('tet-banner__switch-button')

            slide.classList.contains('tet-banner__slide--helio') &&
                switchButton.classList.add('is-helio')
            slide.classList.contains('tet-banner__slide--tet') &&
                switchButton.classList.add('is-tet')

            slide.classList.contains('is-active') && switchButton.classList.add('is-active')

            switchButtons.push(switchButton)
            switchEl.appendChild(switchButton)
        })

        // -------------------- SLIDE ANIMATION --------------------

        let isAnimationActive = false

        const setAnimation = ({ status }) => {
            isAnimationActive = status
        }

        const switchSlideAnimation = ({
            oldSlideIndex,
            newSlideIndex,
            direction,
            tempo = 1,
            easing = 'easeInOut',
            timingModifier = 1,
        }) => {
            const oldTextContainer = bannerSlides[oldSlideIndex].querySelector(
                '.js-tet-banner__text-container'
            )
            const oldPriceContainer = bannerSlides[oldSlideIndex].querySelector(
                '.js-tet-banner__price-container'
            )
            const oldActionContainer = bannerSlides[oldSlideIndex].querySelector(
                '.js-tet-banner__action-container'
            )

            const newTextContainer = bannerSlides[newSlideIndex].querySelector(
                '.js-tet-banner__text-container'
            )
            const newPriceContainer = bannerSlides[newSlideIndex].querySelector(
                '.js-tet-banner__price-container'
            )
            const newActionContainer = bannerSlides[newSlideIndex].querySelector(
                '.js-tet-banner__action-container'
            )

            const switchSlideAnimationTimeline = new TimelineLite()

            switchSlideAnimationTimeline.timeScale(tempo / 1)

            const containerFadeDuration = 1
            const textFadeDuration = 0.5

            const containerFadeDistance = direction * 100
            const textFadeDistance = direction * 100 * 0.4

            switchSlideAnimationTimeline
                .call(() => setAnimation({ status: true }))
                .fromTo(
                    [oldTextContainer, oldPriceContainer, oldActionContainer],
                    textFadeDuration,
                    { x: '0%', autoAlpha: 1 },
                    {
                        x: `${textFadeDistance * -1}%`,
                        autoAlpha: 0,
                        ease: Power3[easing],
                    },
                    `start`
                )
                .fromTo(
                    bannerSlides[oldSlideIndex],
                    containerFadeDuration,
                    { x: '0%' },
                    {
                        x: `${containerFadeDistance * -1}%`,
                        ease: Power3[easing],
                    },
                    `start`
                )
                .fromTo(
                    bannerSlides[newSlideIndex],
                    containerFadeDuration,
                    { x: `${containerFadeDistance}%` },
                    {
                        x: `0%`,
                        ease: Power3[easing],
                    },
                    `start`
                )
                .fromTo(
                    [newTextContainer, newPriceContainer, newActionContainer],
                    textFadeDuration,
                    { x: `${textFadeDistance}%`, autoAlpha: 0 },
                    {
                        x: `0%`,
                        autoAlpha: 1,
                        ease: Power3[easing],
                    },
                    `start+=${containerFadeDuration * 0.3 * timingModifier}`
                )
                .call(() => setAnimation({ status: false }))
        }

        const switchSlides = ({
            oldSlideIndex = null,
            direction = null,
            newSlideIndex,
            tempo,
            easing,
            timingModifier,
        }) => {
            if (isAnimationActive) {
                return
            }

            if (!oldSlideIndex) {
                oldSlideIndex = Array.from(switchButtons).findIndex(switchButton =>
                    switchButton.classList.contains('is-active')
                )
            }

            if (oldSlideIndex === newSlideIndex) {
                return
            }

            if (!direction) {
                direction = newSlideIndex > oldSlideIndex ? 1 : -1
            }

            switchButtons[oldSlideIndex].classList.remove('is-active')
            switchButtons[newSlideIndex].classList.add('is-active')

            switchSlideAnimation({
                oldSlideIndex,
                newSlideIndex,
                direction,
                tempo,
                easing,
                timingModifier,
            })
        }

        Array.from(switchButtons).map((switchButton, switchButtonIndex) => {
            switchButton.addEventListener('click', () =>
                switchSlides({ newSlideIndex: switchButtonIndex })
            )
        })

        const bannerSwiper = new Hammer(banner)
        bannerSwiper.on('swipe', e => {
            const oldSlideIndex = Array.from(switchButtons).findIndex(switchButton =>
                switchButton.classList.contains('is-active')
            )
            const viewportWidth = getViewportWidth()

            let newSlideIndex
            let direction
            let tempo = 3.4

            if (viewportWidth >= 1280) return
            else if (viewportWidth >= 980) tempo = 1.9
            else if (viewportWidth >= 720) tempo = 2.3
            else if (viewportWidth >= 480) tempo = 2.5

            if (e.direction === 4) {
                newSlideIndex =
                    oldSlideIndex === 0 ? Array.from(switchButtons).length - 1 : oldSlideIndex - 1
                direction = -1
            } else if (e.direction === 2) {
                newSlideIndex =
                    oldSlideIndex === Array.from(switchButtons).length - 1 ? 0 : oldSlideIndex + 1
                direction = 1
            }

            if (e.direction === 4 || e.direction === 2) {
                setTimeout(() => {
                    switchSlides({
                        oldSlideIndex,
                        direction,
                        newSlideIndex,
                        tempo,
                        easing: 'easeOut',
                        timingModifier: 0,
                    })
                }, 0)

                window.clearTimeout(bannerAutoSwitchTimeout)
                bannerAutoSwitchFunction()
            }
        })

        // -------------------- SLIDE AUTO SWITCH --------------------

        let bannerAutoSwitchTimeout

        banner.addEventListener('mouseover', () => window.clearTimeout(bannerAutoSwitchTimeout))
        banner.addEventListener('mouseleave', () => bannerAutoSwitchFunction())

        const bannerAutoSwitchFunction = () => {
            bannerAutoSwitchTimeout = window.setTimeout(() => {
                const oldSlideIndex = Array.from(switchButtons).findIndex(switchButton =>
                    switchButton.classList.contains('is-active')
                )
                if (Array.from(switchButtons).length > oldSlideIndex + 1) {
                    setTimeout(() => {
                        switchSlides({
                            newSlideIndex: oldSlideIndex + 1,
                            direction: 1, // Always forward
                        })
                    }, 0)
                } else {
                    setTimeout(() => {
                        switchSlides({
                            newSlideIndex: 0,
                            direction: 1, // Always forward
                        })
                    }, 0)
                }
                bannerAutoSwitchFunction()
            }, 6000)
        }

        bannerAutoSwitchFunction()

        // --------------- CUSTOM EVENTS --------------------------

        const bannerNextSlide = () => {
            const oldSlideIndex = Array.from(switchButtons).findIndex(switchButton =>
                switchButton.classList.contains('is-active')
            )
            if (Array.from(switchButtons).length > oldSlideIndex + 1) {
                switchSlides({
                    newSlideIndex: oldSlideIndex + 1,
                    direction: 1, // Always forward
                })
            } else {
                switchSlides({
                    newSlideIndex: 0,
                    direction: 1, // Always forward
                })
            }
        }

        const bannerPreviousSlide = () => {
            const oldSlideIndex = Array.from(switchButtons).findIndex(switchButton =>
                switchButton.classList.contains('is-active')
            )
            if (oldSlideIndex == 0) {
                switchSlides({
                    newSlideIndex: Array.from(switchButtons).length - 1,
                    direction: -1,
                })
            } else {
                switchSlides({
                    newSlideIndex: oldSlideIndex - 1,
                    direction: -1,
                })
            }
        }

        banner.addEventListener('tet.banner.next', () => {
            bannerNextSlide()
        })

        banner.addEventListener('tet.banner.previous', () => {
            bannerPreviousSlide()
        })
    }
}

export { init }
