import { TimelineLite, TweenMax, Power3 } from 'gsap/all'

import { getDocumentElements } from '../utils'

const [tabs] = getDocumentElements([{ className: 'js-tet-tabs', getAllElements: true }])

const init = () => {
    if (tabs) {
        const switchSlidesAnimation = ({ oldIndex, newIndex, slides }) => {
            const switchSlidesAnimationTimeline = new TimelineLite()

            switchSlidesAnimationTimeline.timeScale(1 / 1)

            const fadeOutDuration = 0.2
            const fadeInDuration = 0.3

            switchSlidesAnimationTimeline
                .fromTo(slides[oldIndex], fadeOutDuration, { autoAlpha: 1 }, { autoAlpha: 0 })
                .to(slides[oldIndex], 0, { position: 'absolute' })
                .call(swapActiveSlideClass, [{ slides, oldIndex, newIndex }])
                .to(slides[newIndex], 0, { position: 'relative' })
                .fromTo(slides[newIndex], fadeInDuration, { autoAlpha: 0 }, { autoAlpha: 1 })
        }

        const switchSlides = ({ tabSwitches, tabIndicator, slides, newIndex }) => {
            let offsetLeft = 0

            for (let i = 0; i < newIndex; i++) {
                offsetLeft += tabSwitches[i].getBoundingClientRect().width
            }

            offsetLeft -= tabIndicator.parentNode.getBoundingClientRect().width / 2
            offsetLeft += tabSwitches[newIndex].getBoundingClientRect().width / 2

            TweenMax.to(tabIndicator.parentNode, 0.4, {
                scrollTo: { x: offsetLeft, ease: Power3.easeOut },
            })

            const oldIndex = Array.from(tabSwitches).findIndex(tab =>
                tab.classList.contains('is-active')
            )

            if (oldIndex !== newIndex) {
                tabSwitches[oldIndex].classList.remove('is-active')
                tabSwitches[newIndex].classList.add('is-active')

                transformTabIndicator({
                    tabSwitches,
                    tabIndicator,
                    newTabIndex: newIndex,
                    duration: 0.4,
                })

                switchSlidesAnimation({ oldIndex, newIndex, slides })
            }
        }

        const transformTabIndicator = ({ tabSwitches, tabIndicator, newTabIndex, duration }) => {
            const newLeft = tabSwitches[newTabIndex].offsetLeft
            const newWidth = tabSwitches[newTabIndex].offsetWidth

            TweenMax.to(tabIndicator, duration, {
                x: newLeft,
                width: newWidth,
                ease: Power3.easeOut,
            })
        }

        // Init tab components + tabIndicator

        Array.from(tabs).map(tab => {
            const slides = tab.querySelectorAll('.js-tet-tabs__slide')
            const tabSwitches = tab.querySelectorAll('.js-tet-tabs__tab')
            const tabIndicator = tab.querySelector('.js-tet-tabs__indicator')

            // -------------------- INIT TABS --------------------

            Array.from(slides).map((slide, slideIndex) => {
                if (slide.classList.contains('is-active')) {
                    transformTabIndicator({
                        tabSwitches,
                        tabIndicator,
                        newTabIndex: slideIndex,
                        duration: 0,
                    })
                    tabIndicator.classList.add('is-visible')
                    tabSwitches[slideIndex].classList.add('is-active')
                }
            })

            Array.from(tabSwitches).map((tabSwitch, tabIndex) => {
                if (tabSwitch.getAttribute('tet-listener') !== 'true') {
                    tabSwitch.addEventListener('click', () =>
                        switchSlides({ tabSwitches, tabIndicator, slides, newIndex: tabIndex })
                    )
                    tabSwitch.setAttribute('tet-listener', 'true')
                }
            })
        })

        const swapActiveSlideClass = ({ slides, oldIndex, newIndex }) => {
            slides[oldIndex].classList.remove('is-active')
            slides[newIndex].classList.add('is-active')
        }

        // Recalculate tabIndicator position on resize, debounced

        let resizeTimer = null

        window.addEventListener('resize', () => {
            clearTimeout(resizeTimer)
            resizeTimer = setTimeout(() => {
                Array.from(tabs).map(tab => {
                    const tabSwitches = tab.querySelectorAll('.js-tet-tabs__tab')
                    const tabIndicator = tab.querySelector('.js-tet-tabs__indicator')

                    const newIndex = Array.from(tabSwitches).findIndex(tabSwitch =>
                        tabSwitch.classList.contains('is-active')
                    )

                    transformTabIndicator({
                        tabSwitches,
                        tabIndicator,
                        newTabIndex: newIndex,
                        duration: 0.4,
                    })

                    let offsetLeft = 0

                    for (let i = 0; i < newIndex; i++) {
                        offsetLeft += tabSwitches[i].getBoundingClientRect().width
                    }

                    offsetLeft -= tabIndicator.parentNode.getBoundingClientRect().width / 2
                    offsetLeft += tabSwitches[newIndex].getBoundingClientRect().width / 2

                    TweenMax.to(tabIndicator.parentNode, 0.4, {
                        scrollTo: { x: offsetLeft, ease: Power3.easeOut },
                    })
                })
            }, 200)
        })

        document.fonts.ready.then(() => {
            Array.from(tabs).map(tab => {
                const tabSwitches = tab.querySelectorAll('.js-tet-tabs__tab')
                const tabIndicator = tab.querySelector('.js-tet-tabs__indicator')

                const newIndex = Array.from(tabSwitches).findIndex(tabSwitch =>
                    tabSwitch.classList.contains('is-active')
                )

                transformTabIndicator({
                    tabSwitches,
                    tabIndicator,
                    newTabIndex: newIndex,
                    duration: 0.4,
                })
            })
        })
    }
}

export { init }
