import { getDocumentElements, getScrollTop, getScrollLeft } from '../utils'

const [tooltips] = getDocumentElements([{ className: 'js-tet-tooltip', getAllElements: true }])

const style = document.createElement('style')
document.head.appendChild(style)

const init = () => {
    if (tooltips) {
        const showTooltip = e => {
            e.target.classList.add('active')

            const tooltipContent = e.target.getElementsByClassName('tet-tooltip__inner')

            const tooltipLeft = e.target.getBoundingClientRect().left
            const tooltipTop = e.target.getBoundingClientRect().top
            const tooltipRight = e.target.getBoundingClientRect().right
            const tooltipBottom = e.target.getBoundingClientRect().bottom
            const tooltipHeight = e.target.getBoundingClientRect().height
            const tooltipWidth = e.target.getBoundingClientRect().width
            const tooltipContentHeight = tooltipContent[0].getBoundingClientRect().height
            const tooltipContentWidth = tooltipContent[0].getBoundingClientRect().width

            const windowWidth = window.innerWidth
            const windowHeight = window.innerHeight

            const half = tooltipContentHeight / 2 - tooltipHeight / 2

            const errorMargin = 10
            const spacing = 14
            const spacingSystem = 7

            const positioning = () => {
                style.innerHTML = ``
                if (tooltipContent[0].classList.contains('tet-tooltip__inner-system')) {
                    if (
                        tooltipTop > tooltipContentHeight + errorMargin &&
                        tooltipContentWidth / 2 - tooltipWidth / 2 < tooltipLeft &&
                        tooltipContentWidth / 2 - tooltipWidth / 2 <
                            windowWidth - (tooltipLeft + tooltipWidth)
                    ) {
                        tooltipContent[0].style.left = `-${tooltipContentWidth / 2 -
                            tooltipWidth / 2}px`
                        tooltipContent[0].style.bottom = `${tooltipHeight + spacingSystem}px`
                    } else if (
                        windowHeight - tooltipBottom > tooltipContentHeight + errorMargin &&
                        tooltipContentWidth / 2 - tooltipWidth / 2 < tooltipLeft &&
                        tooltipContentWidth / 2 - tooltipWidth / 2 <
                            windowWidth - (tooltipLeft + tooltipWidth)
                    ) {
                        tooltipContent[0].style.left = `-${tooltipContentWidth / 2 -
                            tooltipWidth / 2}px`
                        tooltipContent[0].style.top = `${tooltipHeight + spacingSystem}px`
                    } else if (
                        windowWidth - tooltipLeft > tooltipContentWidth + errorMargin &&
                        half < tooltipTop &&
                        half < windowHeight - tooltipBottom
                    ) {
                        tooltipContent[0].style.top = `${tooltipHeight / 2 -
                            tooltipContentHeight / 2}px`
                        tooltipContent[0].style.left = `${tooltipWidth + spacingSystem}px`
                    } else if (
                        tooltipLeft > tooltipContentWidth + errorMargin &&
                        half < tooltipTop &&
                        half < windowHeight - tooltipBottom
                    ) {
                        tooltipContent[0].style.top = `${tooltipHeight / 2 -
                            tooltipContentHeight / 2}px`
                        tooltipContent[0].style.left = `-${tooltipContentWidth + spacingSystem}px`
                    }
                } else {
                    if (windowWidth > 479) {
                        if (
                            tooltipTop > tooltipContentHeight + errorMargin &&
                            tooltipContentWidth / 2 - tooltipWidth / 2 < tooltipLeft &&
                            tooltipContentWidth / 2 - tooltipWidth / 2 <
                                windowWidth - (tooltipLeft + tooltipWidth)
                        ) {
                            tooltipContent[0].classList.add('tet-tooltip__inner-top')
                            tooltipContent[0].style.left = `-${tooltipContentWidth / 2 -
                                tooltipWidth / 2}px`
                            tooltipContent[0].style.bottom = `${tooltipHeight + spacing}px`
                        } else if (
                            windowHeight - tooltipBottom > tooltipContentHeight + errorMargin &&
                            tooltipContentWidth / 2 - tooltipWidth / 2 < tooltipLeft &&
                            tooltipContentWidth / 2 - tooltipWidth / 2 <
                                windowWidth - (tooltipLeft + tooltipWidth)
                        ) {
                            tooltipContent[0].classList.add('tet-tooltip__inner-bottom')
                            tooltipContent[0].style.left = `-${tooltipContentWidth / 2 -
                                tooltipWidth / 2}px`
                            tooltipContent[0].style.top = `${tooltipHeight + spacing}px`
                        } else if (
                            windowWidth - tooltipLeft > tooltipContentWidth + errorMargin &&
                            half < tooltipTop &&
                            half < windowHeight - tooltipBottom
                        ) {
                            tooltipContent[0].classList.add('tet-tooltip__inner-right')
                            tooltipContent[0].style.left = `${tooltipWidth + spacing}px`
                            tooltipContent[0].style.top = `${tooltipHeight / 2 -
                                tooltipContentHeight / 2}px`
                        } else if (
                            tooltipLeft > tooltipContentWidth + errorMargin &&
                            half < tooltipTop &&
                            half < windowHeight - tooltipBottom
                        ) {
                            tooltipContent[0].classList.add('tet-tooltip__inner-left')
                            tooltipContent[0].style.left = `-${tooltipContentWidth + spacing}px`
                            tooltipContent[0].style.top = `${tooltipHeight / 2 -
                                tooltipContentHeight / 2}px`
                        } else if (
                            tooltipTop < tooltipContentHeight + errorMargin &&
                            tooltipContentWidth / 2 - tooltipWidth / 2 > tooltipLeft &&
                            tooltipContentWidth / 2 - tooltipWidth / 2 <
                                windowWidth - (tooltipLeft + tooltipWidth) &&
                            windowHeight - tooltipBottom > tooltipContentHeight
                        ) {
                            tooltipContent[0].classList.add('tet-tooltip__inner-custom--br')
                            tooltipContent[0].style.left = `-4px`
                            tooltipContent[0].style.top = `${tooltipHeight + spacing}px`
                            style.innerHTML = `.tet-tooltip__inner:after {
                                left: ${tooltipLeft - 32 + tooltipWidth / 2 + 11}px;
                                top: -6px;
                            }`
                        } else if (
                            tooltipTop < tooltipContentHeight + errorMargin &&
                            tooltipContentWidth / 2 - tooltipWidth / 2 < tooltipLeft &&
                            tooltipContentWidth / 2 - tooltipWidth / 2 >
                                windowWidth - (tooltipLeft + tooltipWidth) &&
                            windowHeight - tooltipBottom > tooltipContentHeight
                        ) {
                            tooltipContent[0].classList.add('tet-tooltip__inner-custom--bl')
                            tooltipContent[0].style.left = `-${tooltipContentWidth -
                                tooltipWidth -
                                8}px`
                            tooltipContent[0].style.top = `${tooltipHeight + spacing}px`
                            style.innerHTML = `.tet-tooltip__inner:after {
                                left: ${tooltipContentWidth -
                                    (windowWidth - tooltipRight) -
                                    tooltipWidth / 2}px;
                                top: -6px;
                            }`
                        } else if (
                            windowHeight - tooltipBottom < tooltipContentHeight + errorMargin &&
                            tooltipLeft < tooltipContentWidth &&
                            windowWidth - tooltipLeft > tooltipContentWidth &&
                            tooltipTop > tooltipContentHeight
                        ) {
                            tooltipContent[0].classList.add('tet-tooltip__inner-custom--tr')
                            tooltipContent[0].style.left = `-4px`
                            tooltipContent[0].style.bottom = `${tooltipHeight + spacing}px`
                            style.innerHTML = `.tet-tooltip__inner:after {
                                left: ${tooltipLeft - 32 + tooltipWidth / 2 + 11}px;
                                bottom: -6px;
                            }`
                        } else if (
                            windowHeight - tooltipBottom < tooltipContentHeight + errorMargin &&
                            tooltipLeft > tooltipContentWidth &&
                            windowWidth - tooltipLeft < tooltipContentWidth &&
                            tooltipTop > tooltipContentHeight
                        ) {
                            tooltipContent[0].classList.add('tet-tooltip__inner-custom--tl')
                            tooltipContent[0].style.left = `-${tooltipContentWidth -
                                tooltipWidth -
                                8}px`
                            tooltipContent[0].style.bottom = `${tooltipHeight + spacing}px`
                            style.innerHTML = `.tet-tooltip__inner:after {
                                left: ${tooltipContentWidth -
                                    (windowWidth - tooltipRight) -
                                    tooltipWidth / 2}px;
                                bottom: -6px;
                            }`
                        }
                    } else {
                        if (tooltipTop > tooltipContentHeight + errorMargin) {
                            if (tooltipContent[0].classList.contains('tet-tooltip__inner-small')) {
                                tooltipContent[0].style.width = `${tooltipContentWidth}px`
                            } else {
                                tooltipContent[0].style.width = `${windowWidth - 26}px`
                            }
                            tooltipContent[0].classList.add('tet-tooltip__inner-top')
                            tooltipContent[0].style.left = `-${tooltipLeft - 13}px`
                            tooltipContent[0].style.bottom = `${tooltipHeight + spacing}px`
                            style.innerHTML = `.tet-tooltip__inner:after {
                                left: ${tooltipLeft - 32 + tooltipWidth / 2 + 11}px;
                                bottom: -6px;
                            }`
                        } else if (
                            windowHeight - tooltipBottom >
                            tooltipContentHeight + errorMargin
                        ) {
                            if (tooltipContent[0].classList.contains('tet-tooltip__inner-small')) {
                                tooltipContent[0].style.width = `${tooltipContentWidth}px`
                            } else {
                                tooltipContent[0].style.width = `${windowWidth - 26}px`
                            }
                            tooltipContent[0].classList.add('tet-tooltip__inner-bottom')
                            tooltipContent[0].style.left = `-${tooltipLeft - 13}px`
                            tooltipContent[0].style.top = `${tooltipHeight + spacing}px`
                            style.innerHTML = `.tet-tooltip__inner:after {
                                left: ${tooltipLeft - 32 + tooltipWidth / 2 + 11}px;
                                top: -6px;
                            }`
                        } else {
                            if (tooltipContent[0].classList.contains('tet-tooltip__inner-small')) {
                                tooltipContent[0].style.width = `${tooltipContentWidth}px`
                            } else {
                                tooltipContent[0].style.width = `${windowWidth - 26}px`
                            }
                            tooltipContent[0].classList.add('tet-tooltip__inner-top')
                            tooltipContent[0].style.left = `-${tooltipLeft - 13}px`
                            tooltipContent[0].style.bottom = `${tooltipHeight + spacing}px`
                            style.innerHTML = `.tet-tooltip__inner:after {
                                left: ${tooltipLeft - 32 + tooltipWidth / 2 + 11}px;
                                bottom: -6px;
                            }`
                        }
                    }
                }
            }

            positioning()
        }

        const hideTooltip = e => {
            e.target.classList.remove('active')
            const tooltipContent = e.target.getElementsByClassName('tet-tooltip__inner')
            tooltipContent[0].classList.remove(
                'tet-tooltip__inner-top',
                'tet-tooltip__inner-bottom',
                'tet-tooltip__inner-right',
                'tet-tooltip__inner-left',
                'tet-tooltip__inner-custom',
                'tet-tooltip__inner-custom--br',
                'tet-tooltip__inner-custom--tr',
                'tet-tooltip__inner-custom--bl',
                'tet-tooltip__inner-custom--tl'
            )
            tooltipContent[0].style.animation = 'fadeOut 75ms ease-in'
            setTimeout(() => {
                tooltipContent[0].style = ''
            }, 100)
        }

        Array.from(tooltips).map(tooltips => {
            tooltips.addEventListener('mouseenter', showTooltip)
            tooltips.addEventListener('mouseleave', hideTooltip)
        })
    }
}

export { init }
